<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">
    <!-- 배치작업 설정 --------------------------------------------------------------------------------------------->

    <BCard header-tag="header" footer-tag="footer">
      <div slot="header">
         <CIcon name="cilApplications" class="mr-2"/> <strong> 시스템 서비스 및 작업 설정 </strong>
        <div class="card-header-actions">
          <small class="text-muted">시스템 서비스를 정의하고 배치작업 및 기준시간을 설정합니다. </small>
        </div>
      </div>

      <BRow>
        <BCol xl="6">
          <CCard border-color="info"  style="min-height: 600px">
            <CCardHeader>
              <BRow>
                <BCol>
                  <BIconServer class="mr-2"/> 시스템 서비스
                </BCol>
                <BCol class="text-right">
                  <BButtonGroup>
                    <BButton size="sm" variant="primary" class="mr-1" @click="addService">
                      <BIconNodePlusFill/> 서비스 추가
                    </BButton>
                    <BButton size="sm" variant="primary" type="submit" @click="applyService">
                      <BIconBootstrapReboot/> 설정 반영
                    </BButton>
                  </BButtonGroup>
                </BCol>
              </BRow>
            </CCardHeader>

            <CCardBody>

              <BTable
                small
                bordered
                responsive
                selectable
                select-mode="single"
                selected-variant="danger"
                sticky-header
                sticky-column
                ref="serviceTable"
                thead-class="text-center"
                head-variant="light"
                class="text-nowrap small text-center"
                :fields="serviceFields"
                :items="serviceRows"
                @row-selected="serviceSelected"
              >
                <template #cell(status)="row">
                  <BBadge :variant="row.item.status==='listening'?'success':'danger'">{{ row.item.status }}
                  </BBadge>
                </template>

              </BTable>

              <BCard class="p-2 small mb-0" v-if="service!==null" border-variant="info" no-body>
                <BForm @submit="serviceSubmit">
                  <!-- serviceName -->
                  <BFormGroup
                    size="sm" class="mb-1"
                    label="서비스 명"
                    label-for="serviceName"
                    label-cols-md="3"
                    valid-feedback="사용 가능한 서비스명입니다"
                    invalid-feedback="예) ATG 관제 서비스"
                    :state="service.name.length > 2"
                  >
                    <BFormInput size="sm" id="serviceName"
                                v-model="service.name"
                                :state="service.name.length > 2"
                                maxLength="50"
                                required
                                trim/>
                  </BFormGroup>
                  <!-- serviceDesc -->
                  <BFormGroup
                    size="sm" class="mb-1"
                    label="서비스 설명"
                    label-for="serviceDesc"
                    label-cols-md="3"
                    :state="service.description.length > 2"
                  >
                    <BFormInput size="sm" id="serviceDesc"
                                v-model="service.description"
                                :state="service.description.length > 2"
                                maxLength="200"
                                trim/>
                  </BFormGroup>
                  <!-- serviceEnabled -->
                  <BFormGroup
                    size="sm" class="mb-1"
                    label="서비스 사용"
                    label-for="svcEnabled"
                    label-cols-md="3"
                  >
                    <BFormCheckbox id="svcEnabled"
                                   class="mt-1"
                                   v-model="service.enabled" switch>
                      {{`${service.enabled?'사용':'사용 중지'}`}}
                    </BFormCheckbox>
                  </BFormGroup>
                  <!-- companyId -->
                  <BFormGroup
                    size="sm" class="mb-1"
                    label="서비스 식별 코드"
                    label-for="companyId"
                    label-cols-md="3"
                    valid-feedback="사용 가능한 코드입니다"
                    invalid-feedback="숫자 4자 입력 예) 0009"
                    :state="isValidExp(service.companyId, 'num', 4)"
                  >
                    <BFormInput size="sm" id="companyId"
                                v-model="service.companyId"
                                @input="v=>(service.companyId = v.toUpperCase())"
                                :state="isValidExp(service.companyId, 'num', 4)"
                                maxLength="4"
                                required
                                trim/>
                  </BFormGroup>
                  <!-- siteId -->
                  <BFormGroup
                    size="sm" class="mb-1"
                    label="사이트 식별 코드"
                    label-for="siteId"
                    label-cols-md="3"
                    valid-feedback="사용 가능한 코드입니다"
                    invalid-feedback="16진수 4자 예) 1F30"
                    :state="isValidExp(service.siteId, 'hex', 4)"
                  >
                    <BFormInput size="sm" id="siteId"
                                v-model="service.siteId" :state="isValidExp(service.siteId, 'hex', 4)"
                                @input="v=>(service.siteId = v.toUpperCase())"
                                maxLength="4"
                                required
                                trim/>
                  </BFormGroup>
                  <!-- serverIp -->
                  <BFormGroup
                    size="sm" class="mb-1"
                    label="서버 IP 주소"
                    label-for="serverIp"
                    label-cols-md="3"
                    valid-feedback="IP 주소 형식입니다"
                    invalid-feedback="IP 주소  예) 192.168.10.123"
                    :state="isValidExp(service.serverIp, 'ip')"
                  >
                    <BFormInput size="sm" id="serverIp"
                                v-model="service.serverIp"
                                :state="isValidExp(service.serverIp, 'ip')"
                                maxLength="40"
                                required
                                trim/>
                  </BFormGroup>
                  <!-- port# -->
                  <BFormGroup
                    size="sm"  class="mb-1"
                    label="서비스 포트 번호"
                    label-for="servicePort"
                    label-cols-md="3"
                    valid-feedback="포트번호 형식입니다."
                    invalid-feedback="포트번호 1000 ~ 65535 사이 값"
                    :state="service.port > 999 && service.port < 65536"
                  >
                    <BFormInput size="sm"
                                id="servicePort"
                                name="port"
                                type="number"
                                :state="service.port > 999 && service.port < 65536"
                                v-model="service.port"
                    />
                  </BFormGroup>
                  <!-- timeout -->
                  <BFormGroup
                    size="sm"  class="mb-1"
                    label="서비스 타임아웃"
                    label-for="serviceTimeout"
                    label-cols-md="3"
                    valid-feedback="미리세컨드 숫자 형식입니다."
                    invalid-feedback="미리세컨드 입력"
                    :state="service.timeout >= 0 && service.timeout < 9999999"
                  >
                    <BFormInput size="sm"
                                id="serviceTimeout"
                                name="timeout"
                                type="number"
                                :state="service.timeout >= 0 && service.timeout < 9999999"
                                v-model="service.timeout"
                    />
                  </BFormGroup>
                  <!-- gateway -->
                  <BFormGroup
                    size="sm" class="mb-1"
                    label="Gateway IP"
                    label-for="gatewayIp"
                    label-cols-md="3"
                    valid-feedback="IP 주소 형식입니다"
                    invalid-feedback="IP 주소  예) 192.168.10.1"
                    :state="isValidExp(service.gateway, 'ip')"
                  >
                    <BFormInput size="sm" id="gatewayIp"
                                v-model="service.gateway"
                                :state="isValidExp(service.gateway, 'ip')"
                                maxLength="40"
                                required
                                trim/>
                  </BFormGroup>
                  <!-- subnet -->
                  <BFormGroup
                    size="sm" class="mb-2"
                    label="서브넷"
                    label-for="subnet"
                    label-cols-md="3"
                    valid-feedback="서브넷 형식입니다"
                    invalid-feedback="서브넷 형식 예) 255.255.255.0"
                    :state="isValidExp(service.subnet, 'ip')"
                  >
                    <BFormInput size="sm" id="subnet"
                                v-model="service.subnet"
                                :state="isValidExp(service.subnet, 'ip')"
                                maxLength="40"
                                required
                                trim/>
                  </BFormGroup>
                  <!-- status -->
                  <BFormGroup
                    size="sm" class="mb-2"
                    label="현재상태"
                    label-for="status"
                    label-cols-md="3"
                    :state="service.status==='listening'"
                  >
                    <BFormInput size="sm" id="status"
                                v-model="service.status"
                                :state="service.status==='listening'"
                                readonly/>
                  </BFormGroup>
                  <hr>
                  <!--packetType-->
                  <CSelect size="sm" class="mb-1"
                           horizontal
                           label="패킷 방식"
                           :value.sync="service.packetType"
                           :options="packetTypeOpts"
                  />
                  <!-- encrypted -->
                  <BFormGroup
                    size="sm" class="mb-1"
                    label="패킷 암호화"
                    label-for="encrypted"
                    label-cols-md="3"
                  >
                    <BFormCheckbox id="encrypted"
                                   class="mt-1"
                                   v-model="service.encrypted" switch>
                      {{`${service.encrypted?'암호화 수신':'평문 수신'}`}}
                    </BFormCheckbox>
                  </BFormGroup>

                  <div v-show="service.encrypted">
                    <!-- encType -->
                    <CSelect size="sm" class="mb-1"
                             horizontal
                             label="암호화 방식"
                             :value.sync="service.encType"
                             :options="encTypeOpts"
                             />
                    <!-- encKey -->
                    <BFormGroup
                      size="sm" class="mb-1"
                      label="패킷 암호 키"
                      label-for="encKey"
                      label-cols-md="3"
                      valid-feedback="암호키 형식입니다"
                      invalid-feedback="16진수 32자 이상 예) 0F0E1099887736250F0E109988773625"
                      :state="isValidExp(service.encKey, 'hex', 64)"
                    >
                      <BFormInput size="sm" id="encKey" type="text"
                                  v-model="service.encKey"
                                  :state="isValidExp(service.encKey, 'hex', 64)"
                                  maxLength="64"
                                  :required="service.encrypted"
                                  trim/>
                    </BFormGroup>
                    <!-- encVector -->
                    <BFormGroup
                      size="sm"
                      label="패킷 암호 벡터"
                      label-for="siteVector"
                      label-cols-md="3"
                      valid-feedback="벡터 형식입니다"
                      invalid-feedback="16자 예) 0F0E1099887736250F0E109988773625"
                      :state="isValidExp(service.vector, 'hex', 32)"
                    >
                      <BFormInput size="sm" id="siteVector" type="text"
                                  v-model="service.vector"
                                  :state="isValidExp(service.vector, 'hex', 32)"
                                  maxLength="32"
                                  :required="service.encrypted"
                                  trim/>
                    </BFormGroup>

                  </div>

                  <div class="row justify-content-between p-3">
                    <BButtonGroup>
                      <BButton type="submit" variant="primary">
                        <BIconSaveFill/> 서비스 설정 저장
                      </BButton>
                    </BButtonGroup>

                    <BButton variant="danger" @click="deleteRecord('service')">
                      <BIconTrashFill/>
                      삭 제
                    </BButton>
                  </div>

                </BForm>
              </BCard>
            </CCardBody>
          </CCard>


        </BCol>

        <BCol xl="6">



          <CCard border-color="info" style="min-height: 600px">
            <CCardHeader>
              <BRow>
                <BCol>
                  <BIconAppIndicator class="mr-2"/> <strong> 시스템 작업 정보 </strong>
                </BCol>
                <BCol class="text-right">
                  <BButtonGroup>
                    <BButton size="sm" variant="info" class="mr-1" @click="getJob">
                      <BIconArrowClockwise/>
                    </BButton>
                    <BButton size="sm" variant="warning" class="mr-3" @click="addJob">
                      <BIconNodePlusFill/> 작업 추가
                    </BButton>
                    <BButton size="sm" variant="primary" type="submit" @click="applyJob" :disabled="isApplyJob">
                      <BIconBootstrapReboot/> 설정 반영
                    </BButton>
                  </BButtonGroup>
                </BCol>
              </BRow>
            </CCardHeader>

            <CCardBody>

              <BTable
                small
                bordered
                responsive
                selectable
                select-mode="single"
                selected-variant="danger"
                sticky-header
                sticky-column
                ref="jobTable"
                thead-class="text-center"
                head-variant="light"
                class="text-nowrap small text-center"
                :fields="jobFields"
                :items="jobRows"
                @row-selected="jobSelected"
              >
                <template #cell(status)="{item}">
                  <BBadge class="small" :variant="statusVariant(item.status)">{{statusName(item.status)}}</BBadge>
                </template>

              </BTable>

              <BForm @submit="jobSubmit" autocomplete="off">
                <BCard class="p-2 small mb-0" v-if="job!==null" border-variant="info" no-body>

                  <!-- jobName -->
                  <BFormGroup
                    size="sm" class="mb-1"
                    label="작업 명"
                    label-for="jobName"
                    label-cols-md="3"
                    valid-feedback="사용 가능한 작업명입니다"
                    invalid-feedback="예) 재고데이터 전송작업"
                    :state="job.name.length > 2"
                  >
                    <BFormInput size="sm" id="jobName"
                                v-model="job.name"
                                :state="job.name.length > 2"
                                maxLength="50"
                                required
                                trim/>
                  </BFormGroup>
                  <!-- description -->
                  <BFormGroup
                    size="sm" class="mb-1"
                    label="작업 설명"
                    label-for="jobDesc"
                    label-cols-md="3"
                  >
                    <BFormInput size="sm" id="jobDesc"
                                v-model="job.description"
                                maxLength="200"
                                trim/>
                  </BFormGroup>
                  <!-- jobId -->
                  <BFormGroup
                    size="sm" class="mb-1"
                    label="작업 코드"
                    label-for="jobId"
                    label-cols-md="3"
                    valid-feedback="사용 가능한 코드입니다"
                    invalid-feedback="숫자 4자 입력 예) 0001"
                    :state="isValidExp(job.jobId, 'num', 4)"
                  >
                    <BFormInput size="sm" id="jobId"
                                v-model="job.jobId"
                                @input="v=>(job.jobId = v.toUpperCase())"
                                :state="isValidExp(job.jobId, 'num', 4)"
                                maxLength="4"
                                required
                                trim/>
                  </BFormGroup>
                  <!-- jobEnabled -->
                  <BFormGroup
                    size="sm" class="mb-1"
                    label="실행여부"
                    label-for="jobEnabled"
                    label-cols-md="3"
                  >
                    <BFormCheckbox id="jobEnabled"
                                   class="mt-1"
                                   v-model="job.enabled" switch>
                      {{`${job.enabled?'작업 실행':'실행 중지'}`}}
                    </BFormCheckbox>
                  </BFormGroup>
                  <div v-show="job.enabled">
                    <!-- scheduled -->
                    <BFormGroup
                      size="sm" class="mb-0"
                      label="실행 일정 설정"
                      label-for="scheduled"
                      label-cols-md="3"
                    >
                      <BFormCheckbox id="scheduled"
                                     class="mt-1"
                                     v-model="job.scheduled" switch>
                        {{`${job.scheduled?'정해진 일정 실행':'특정 이벤트 발생시 실행'}`}}
                      </BFormCheckbox>
                    </BFormGroup>
                    <div v-show="job.scheduled" class="small">
                      <VueCronEditorBuefy v-model="job.runSchedule"
                                          class="small font-weight-bold text-nowrap"
                                          locale="ko"
                                          :custom-locales="locale"

                      />
                    </div>
                    <!-- eventName -->
                    <BFormGroup v-show="!job.scheduled"
                      size="sm" class="mb-1"
                      label="이벤트 명"
                      label-for="eventName"
                      label-cols-md="3"
                    >
                      <BFormInput size="sm" id="eventName"
                                  v-model="job.eventName"
                                  maxLength="40"
                                  trim/>
                    </BFormGroup>
                    <!-- dbType -->
                    <CSelect size="sm" class="mb-1"
                             horizontal
                             label="작업 형태"
                             :value.sync="job.dbType"
                             :options="dbTypeOpts"
                    />
                    <!-- serverIp -->
                    <BFormGroup
                      size="sm" class="mb-1"
                      label="서버 IP 주소"
                      label-for="serverIp2"
                      label-cols-md="3"
                      valid-feedback="IP 주소 형식입니다"
                      invalid-feedback="IP 주소  예) 192.168.10.123"
                      :state="isValidExp(job.serverIp, 'ip')"
                    >
                      <BFormInput size="sm" id="serverIp2"
                                  v-model="job.serverIp"
                                  :state="isValidExp(job.serverIp, 'ip')"
                                  maxLength="40"
                                  trim/>
                    </BFormGroup>
                    <!-- baseUrl -->
                    <BFormGroup
                      size="sm" class="mb-1"
                      label="Base URL"
                      label-for="baseURL"
                      label-cols-md="3"
                      valid-feedback="URL 형식 입니다"
                      invalid-feedback="URL 예) http://192.168.10.123:8080"
                      :state="isValidExp(job.baseUrl, 'url')"
                    >
                      <BFormInput size="sm" id="baseURL"
                                  v-model="job.baseUrl"
                                  :state="isValidExp(job.baseUrl, 'url')"
                                  maxLength="400"
                                  trim/>
                    </BFormGroup>

                    <BFormGroup
                      size="sm" class="mb-1"
                      label="API 경로"
                      label-for="apiURL"
                      label-cols-md="3"
                    >
                      <BFormInput size="sm" id="apiPath"
                                  v-model="job.apiPath"
                                  maxLength="400"
                                  trim/>
                    </BFormGroup>
                    <BFormGroup
                      size="sm" class="mb-1"
                      label="인증 경로"
                      label-for="authPath"
                      label-cols-md="3"
                    >
                      <BFormInput size="sm" id="authPath"
                                  v-model="job.authPath"
                                  maxLength="400"
                                  trim/>
                    </BFormGroup>
                    <!-- port# -->
                    <BFormGroup
                      size="sm"  class="mb-1"
                      label="서버 포트 번호"
                      label-for="portNo"
                      label-cols-md="3"
                      valid-feedback="포트번호 형식입니다."
                      invalid-feedback="포트번호 1000 ~ 65535 사이 값"
                      :state="job.port > 999 && job.port < 65536"
                    >
                      <BFormInput size="sm"
                                  id="portNo"
                                  name="port"
                                  type="number"
                                  :state="job.port > 999 && job.port < 65536"
                                  v-model="job.port"
                      />
                    </BFormGroup>
                    <!-- dbName -->
                    <BFormGroup
                      size="sm" class="mb-1"
                      label="사이트 코드"
                      label-for="siteCode"
                      label-cols-md="3"
                    >
                      <BFormInput size="sm" id="siteCode"
                                  v-model="job.siteCode"
                                  maxLength="40"
                                  trim/>
                    </BFormGroup>
                    <!-- account -->
                    <BFormGroup
                      size="sm" class="mb-1"
                      label="서버 접속 계정"
                      label-for="account"
                      label-cols-md="3"
                    >
                      <BFormInput size="sm" id="account"
                                  autocomplete="nope"
                                  v-model="job.account"
                                  maxLength="40"
                                  trim/>
                    </BFormGroup>
                    <!-- password -->
                    <BFormGroup
                      size="sm" class="mb-1"
                      label="접속 비밀번호"
                      label-for="jobPasswd"
                      label-cols-md="3"
                    >
                      <BFormInput size="sm" id="jobPasswd"
                                  type="password"
                                  autocomplete="nope"
                                  v-model="job.password"
                                  maxLength="40"
                                  trim/>
                    </BFormGroup>
                    <!-- auth-token -->
                    <BFormGroup
                      v-if="false"
                      size="sm" class="mb-1"
                      label="접속 토큰정보"
                      label-for="authToken"
                      label-cols-md="3"
                    >
                      <BFormInput size="sm" id="authToken" type="password"
                                  v-model="job.authToken"
                                  maxLength="40"
                                  trim/>
                    </BFormGroup>
                    <!-- auth-token -->
                    <BFormGroup
                      size="sm" class="mb-1"
                      label="비고"
                      label-for="remark"
                      label-cols-md="3"
                    >
                      <BFormInput size="sm" id="remark"
                                  v-model="job.remark"
                                  maxLength="40"
                                  trim/>
                    </BFormGroup>
                  </div>





                  <div class="row justify-content-between p-3">
                    <BButtonGroup>
                      <BButton type="submit" variant="primary">
                        <BIconSaveFill/> 작업 설정 저장
                      </BButton>
                    </BButtonGroup>

                    <BButton variant="danger" @click="deleteRecord('job')">
                      <BIconTrashFill/>
                      삭 제
                    </BButton>
                  </div>
                </BCard>
              </BForm>
            </CCardBody>

          </CCard>





        </BCol>
      </BRow>
    </BCard>


  </div>
</template>

<style src="spinkit/spinkit.min.css"></style> <!--animated processing icon-->

<script>
//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall,
  cloneVar,
} from '@/common/utils';
import VueCronEditorBuefy from 'vue-cron-editor-buefy';



// import qs from 'querystring';
// import moment from "moment";



const _service = {
  code        : '0000',
  companyId   : '0000',
  name        : '신규서비스',
  siteId      : '0000',
  description : '서비스 설명',
  enabled     : true,
  status      : '00',
  gateway     : '127.0.0.1',
  subnet      : '255.255.255.0',
  serverIp    : '127.0.0.1',
  port        : 3000,
  timeout     : 5000,
  packetType  : 'ATG_TCP',
  encrypted   : true,
  encType     : 'ARIA_CBC',
  encKey      : '',
  vector      : '',
};

const _config_job = {
  jobId      : '0000',
  name       : '',
  description: '작업 설명',
  enabled    : false,
  scheduled  : true,
  runSchedule : '',
  eventName  : '',
  dbType     : '5',
  serverIp   : '127.0.0.1',
  port       : 9999,
  baseUrl    : '',
  authPath   : '',
  apiPath    : '',
  siteCode   : '',
  account    : '',
  password   : '',
  authToken  : '',
  remark     : '',
  status     : '10',
  runHistory : [],
};


//----------------------------------------------------------------------------------------------------
export default {
  name: "Services",
  components: {
    VueCronEditorBuefy
  },
  computed: {},
  data () {
    return {
      service: null,
      serviceRows : [],
      serviceFields : [
        // {key: 'code'       , label: '코드'}, // 서비스 식별 코드
        {key: 'name'       , label: '서비스명'},
        {key: 'companyId'  , label: '식별코드'}, // 서비스 식별 코드
        {key: 'siteId'     , label: '사이트코드'}, // 사이트 식별 코드
        // {key: 'description', label: '설명'},
        {key: 'enabled'    , label: '사용', formatter: (v)=>{return v?'Y':'N'}},
        {key: 'serverIp'   , label: '서버IP'},
        {key: 'port'       , label: '포트#'},
        {key: 'status'     , label: '상태'},
        // {key: 'gateway'    , label: 'G/W IP'},
        // {key: 'subnet'     , label: '서브넷'},
        {key: 'packetType' , label: '패킷형태'},
        {key: 'encrypted'  , label: '암호화', formatter: (v)=>{return v?'Y':'N'}},
        {key: 'encType'    , label: '암호화방식'},
        // {key: 'encKey'     , label: '암호화키'},
        // {key: 'vector'     , label: '벡터'},
        {key: 'regId'      , label: '등록자'},
        {key: 'updId'      , label: '수정자'},
      ],

      job : null,
      jobRows: [],
      jobFields: [
        { key: 'jobId'       , label: '작업코드' },
        { key: 'name'        , label: '작업명' },
        { key: 'description' , label: '작업상세' },
        { key: 'status'      , label: '상태' },
        { key: 'enabled'     , label: '사용여부', formatter: (v)=>{ return v? '사용':'중지'} },
        { key: 'runSchedule' , label: '작업일정' },
        // { key: 'runOnEvent'  , label: '이벤트작업' },
        // { key: 'eventName'   , label: '이벤트명' },
        // { key: 'dbType'      , label: '' },
        // { key: 'serverIp'    , label: '서버IP' },
        // { key: 'port'        , label: '' },
        // { key: 'apiUrl'      , label: '' },
        // { key: 'dbName'      , label: '' },
        // { key: 'account'     , label: '' },
        // { key: 'password'    , label: '' },
        // { key: 'authToken'   , label: '' },

        { key: 'remark'      , label: '비고' },
      ],
      expression: '*/1 * * * *',
      locale : {
        ko: {
          every: "Every",
          mminutes: "분 간격",
          hoursOnMinute: "시/분",
          daysAt: "일/시:분",
          at: "시:분",
          onThe: "일자",
          dayOfEvery: "월",
          monthsAt: "시:분",
          everyDay: "Every",
          mon: "월",
          tue: "화",
          wed: "수",
          thu: "목",
          fri: "금",
          sat: "토",
          sun: "일",
          hasToBeBetween: "Has to be between",
          and: "and",
          minutes: "분간격",
          hourly: "시간간격",
          daily: "일간",
          weekly: "주간",
          monthly: "월간",
          advanced: "커스텀설정",
          cronExpression: "크론 표현식:"
        }
      },

      isApplyJob: false,
      packetTypeMap : this.$store.state.codeMaps['PACKET_TYPE'],
      packetTypeOpts : this.$store.state.codeOpts['PACKET_TYPE'],
      encTypeMap: this.$store.state.codeMaps['ENC_TYPE'],
      encTypeOpts: this.$store.state.codeOpts['ENC_TYPE'],
      dbTypeOpts: this.$store.state.codeOpts['DB_TYPE'],


      progCount: 0,
      totalCount: 0,


      paramMap: {},
      numOfRecord: 10,


    }

  },
  async created(){
    try{
      console.log("--- Services mounted---------------------");
      await this.getService();
      await this.getJob();

    }catch(err){
      console.log(err);
    }
  },


  mounted() {
    console.log("--- Services mounted---------------------");
    // below is not work!
  },

  methods: {
    statusName(sts){
      switch(sts){
        case '00': return '등록';
        case '10': return '초기화';
        case '20': return '작업대기';
        case '30': return '실행중';
        case '99': return '에러';
        default: return sts;
      }
    },

    statusVariant(sts){
      switch(sts){
        case '00': return 'dark';
        case '10': return 'success';
        case '20': return 'info';
        case '30': return 'warning';
        case '99': return 'danger';
        default: return sts;
      }
    },


    async applyService(){
      try{
        const r = await apiCall('get', `/api/service/apply`);
        await this.toastResult(r, `서비스 변경 정보 시스템 반영`);
        if(r.code===200){
          await this.alertSuccess('시스템에 반영 되었습니다.');
          await this.getService();
        }else{
          await this.alertDanger('시스템 적용 실패', r.code);
        }

      }catch(err){
        await this.alertError(err);
      }
    },
    async applyJob(){
      try{
        this.isApplyJob = true;
        await this.toastInfo(`배치작업 정보를 시스템에 적용 중 입니다`);
        const r = await apiCall('get', `/api/job/apply`);
        await this.toastResult(r, `배치작업 변경 정보 시스템 적용`);
        if(r.code===200){
          await this.alertSuccess('배치작업 변경이 적용 되었습니다.');
          await this.getJob();
        }else{
          await this.alertDanger('적용 실패', r.code);
        }

      }catch(err){
        await this.alertError(err);
      }finally{
        this.isApplyJob = false;
      }
    },

    async getService(){
      try{
        const r = await apiCall('get', `/api/service`);
        if(r.code===200){
          this.serviceRows = r.result;
        }else{
          await this.alertDanger(r.message, r.code);
        }

      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }
    },

    async getJob(){
      console.log('getJob called.....');
      try{
        const r = await apiCall('get', `/api/job`);
        if(r.code===200){
          this.jobRows = r.result;
        }else{
          await this.alertDanger(r.message, r.code);
        }

        await this.toastResult(r, '조회', false);

      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }
    },

    async addService(){
      this.service = cloneVar(_service);
      this.service.companyId = this.makeRandom(1000,9999);
      this.service.name = (this.serviceRows.length + 1) + '-신규서비스'
      this.service.port = this.makeRandom(1000, 65535);
      const r = await apiCall('post', `/api/service`, this.service);

      if(r.code===200){
        this.service = r.result;
        await this.getService();

      }
      await this.toastResult(r);
    },

    async addJob(){
      this.job = cloneVar(_config_job);
      this.job.jobId = ('0000'+(this.jobRows.length+1)).slice(-4);
      this.job.name = this.job.jobId + '-job-name'
      this.job.description = '작업 상세 내용'

      const r = await apiCall('post', `/api/job`, this.job);
      if(r.code===200){
        this.job = r.result;
        await this.getJob();
      }
      await this.toastResult(r);
    },

    async serviceSelected(item){
      console.log("------------ serviceSelected ------------", item.length);
      if( item.length === 0 ) {
        this.service = null;
      }else{
        this.service = item.pop();
        console.log( this.service );
      }
    },

    async jobSelected(item){
      console.log("------------ jobSelected ------------", item.length);
      if( item.length === 0 ) {
        this.job = null;
      }else{
        this.job = item.pop();
        // this.$root.$emit('bv::toggle::collapse', 'serviceCollapse');
      }
    },


    async serviceSubmit(evt){
      evt.preventDefault();
      console.log( 'serviceSubmit------------->', this.service );
      try{
        const r = await apiCall('put', `/api/service/${this.service._id}`, this.service );
        console.log('serviceSubmit------------->' , r);
        if(r.code===200){
          this.service = r.result;
        }


        await this.toastResult(r);
      }catch(err){
        console.log(err);
      }
    },

    async jobSubmit(evt){
      evt.preventDefault();
      console.log( 'jobSubmit------------->', this.service );
      try{
        const r = await apiCall('put', `/api/job/${this.job._id}`, this.job );
        console.log('jobSubmit------------->' , r);
        await this.toastResult(r);
      }catch(err){
        console.log(err);
      }
    },


    async deleteRecord(gbn){
      // let r = null, msg = null, notice = null;
      try{
        let obj;
        if(gbn==='service'){
          obj = this.service;
        }else{
          obj = this.job;
        }
        const confirmMsg = `${obj.name} 데이터를 삭제 합니다. 삭제된 데이터는 복구할 수 없으며 시스템 장애가 발생할 수 있습니다. 진행 하시겠습니까?`;

        if( !(await this.confirmModal(confirmMsg, '레코드 삭제')) ){
          return;
        }

        const r = await apiCall('DEL', `/api/${gbn}/${obj._id}`);
        console.log( r );
        if( r.code===200 ){
          await this.getService();
          await this.getJob();
        }else{
          await this.toastInfo('삭제 에러: '+r.message, 'Error', 'danger' );
        }
      }catch(err){
        await this.alertError(err);
      }
    },




  }
}
</script>
